import { ReportSaveType } from '@/hooks/useConstructor';
import { FormModel } from './types';

export const defaultModel = (): FormModel => ({
  date: [null, null],
  sms: [null, null],
  voice: [null, null],
  voice_document_date: [null, null],
  email: [null, null],
  enrichment: [null, null],
  status_updated_at: [null, null],
  court_tracker: [null, null],
  has_estate_object_characteristics: null,
  template_type_id: null,
  has_court_case_id: null,
  has_court_case_number: null,
  is_organization: null,
  save_type: ReportSaveType.excel,
  email_receiver: '',
  schedule: null,
  has_enrichment: null,
  has_notification_lock: null,
  has_doc_egrn: null,
  has_doc_egrn_rights: null,
  fees_await_paid: null,
  is_verified_cad_num: null,
  has_tenant_and: null,
  include_disabled: null,
  invalid_registered_letter_data: null,
  has_directed_for_execution_date: null,
});
